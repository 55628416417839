import { useEffect, useState } from "react"
import { openDB, deleteDB } from "idb"

const SDK_DB_NAME = "ONE_SIGNAL_SDK_DB"
const SDK_ENVIRONMENT_STORAGE_KEY = "sdkEnvironment"

const sdkEnvironmentToUrlMapping = {
  production: "https://cdn.onesignal.com/sdks/OneSignalSDK.js",
  staging: "https://staging.onesignal.com/sdks/Staging-OneSignalSDK.js",
  local: "TODO",
}

type SDKEnvironment = keyof typeof sdkEnvironmentToUrlMapping

const clearApplicationStorage = async () => {
  // Clear the indexedDB
  await deleteDB(SDK_DB_NAME, {
    blocked() {
      console.warn(
        "DB deletion during storage clearing blocked, you may need to clear " +
          "storage via the devtools if this doesn't resolve on its own",
      )
    },
  })

  // Clear other site storage
  localStorage.clear()
  sessionStorage.clear()
  document.cookie = ""

  // Unregister service workers
  navigator.serviceWorker.getRegistrations().then(function (registrations) {
    for (const registration of registrations) {
      registration.unregister()
    }
  })
}

const loadAndInitializeSdk = async (sdkType: SDKEnvironment, appId: string) =>
  new Promise((resolve, reject) => {
    window.OneSignal = window.OneSignal || []
    const script = document.createElement("script")
    script.async = true
    script.src = sdkEnvironmentToUrlMapping[sdkType]
    script.onload = () => {
      window.OneSignal.log.setLevel("trace")
      window.OneSignal.push(() => window.OneSignal.init({ appId }).then(resolve, reject))
    }
    document.body.appendChild(script)
  })

const clearStorage = async () => {
  try {
    return clearApplicationStorage()
  } catch (error) {
    alert(
      "Failed to clear storage, please check the dev console. You should " +
        "probably use the devtools to clear all application storage manually and then reload.",
    )
    console.error(error)
  }
}

type SDKConfiguration =
  | { loadState: "loading" }
  | {
      loadState: "failed"
      errorMessage: string
    }
  | {
      loadState: "succeeded"
      appId: string
      environment: SDKEnvironment
    }

const getAppIdFromSdkDb = async (): Promise<string | undefined> => {
  const db = await openDB(SDK_DB_NAME)

  try {
    const appIdResult = await db.get("Ids", "appId")
    await db.close()
    return appIdResult?.id
  } catch (error) {
    // Opening the database appears to create it automatically, based on empirical testing, though
    // the MDN docs don't specify its behavior either way. However, the query will throw in that
    // case, and this will catch it. The auto-created database then has to be cleaned up otherwise
    // the OneSignal SDK breaks (since the db exists but critical collections are missing)
    await db.close()
    await deleteDB(SDK_DB_NAME)
  }
}

function App() {
  const searchParams = new URL(document.location.href).searchParams
  const sdkEnvironmentFromSearchParams = searchParams.get("sdk_environment")

  const shouldClearStorage = searchParams.get("clear_storage") === "true"
  const appIdFromSearchParams = searchParams.get("app_id")
  const sdkEnvironment = sdkEnvironmentFromSearchParams
    ? (sdkEnvironmentFromSearchParams as SDKEnvironment)
    : undefined

  const [newAppIdDraft, setNewAppIdDraft] = useState("")
  const [sdkConfiguration, setSdkConfiguration] = useState<SDKConfiguration>()

  // Initialize on page load and load app if configured
  useEffect(() => {
    async function init() {
      try {
        // Storage must be cleared before the next SDK load or it'll start using the data that's
        // there rather than the intended settings. Also, once storage is cleared, remove the url
        // flag so it isn't part of any links people copy.
        if (shouldClearStorage) {
          await clearStorage()
          window.location.search = ""
        }

        const persistedAppId = await getAppIdFromSdkDb()
        const appId = appIdFromSearchParams || persistedAppId

        if (appId && sdkEnvironment) {
          setSdkConfiguration({ loadState: "loading" })
        }

        // If there's a stored appId (and thus configuration) from a previously loaded SDK instance,
        // but a different one was requested in the query params, reset everything and then use the
        // one in the query params
        if (persistedAppId && persistedAppId !== appIdFromSearchParams) {
          console.info(
            "The app id in the url is different from the one saved by the most recently" +
              "loaded OneSignal SDK instance. All local storage will be cleared " +
              "so the SDK config for the app ID in the url can be loaded.",
            { persistedAppId, appIdFromSearchParams, sdkEnvironment },
          )

          await clearStorage()
        }

        if (appId && sdkEnvironment) {
          try {
            await loadAndInitializeSdk(sdkEnvironment, appId)

            localStorage.setItem(SDK_ENVIRONMENT_STORAGE_KEY, sdkEnvironment)

            setSdkConfiguration({
              loadState: "succeeded",
              appId: appId,
              environment: sdkEnvironment,
            })
          } catch (error) {
            setSdkConfiguration({
              loadState: "failed",
              errorMessage: error.message,
            })
          }
        }
      } catch (error) {
        setSdkConfiguration({
          loadState: "failed",
          errorMessage:
            `Failed to check for existing SDK configuration. You should probably ` +
            `use the devtools to manually clear all storage for the site and try again. Further ` +
            `error details: ${error.message}`,
        })
      }
    }
    init()
  }, [appIdFromSearchParams, sdkEnvironment, shouldClearStorage])

  return (
    <div style={{ padding: 40 }}>
      {sdkConfiguration?.loadState === "loading" ? (
        <div>
          SDK configuration detected, loading...
          <div style={{ paddingTop: 80, fontStyle: "italic" }}>
            Note: if this hangs, it's likely that there are multiple tabs open and indexedDB
            connections from the other tab are blocking this tab from doing the database access it
            needs. To resolve this:
            <br />
            1. Close other tabs that are open to this site. That usually unblocks this tab and
            resolve it.
            <br />
            2. If that doesn't work, open devtools, go to application, then storage, then hit clear
            storage until all listed storage types are empty and reload the page.
          </div>
        </div>
      ) : sdkConfiguration?.loadState === "succeeded" ? (
        <div>
          <div>The SDK has been configured with the following settings:</div>
          <div>App ID: {sdkConfiguration.appId}</div>
          <div>Environment: {sdkConfiguration.environment}</div>
        </div>
      ) : sdkConfiguration?.loadState === "failed" ? (
        <div>
          The OneSignal SDK failed to load with this configuration
          <div>App ID: {appIdFromSearchParams}</div>
          <div>Environment: {sdkEnvironmentFromSearchParams}</div>
          <div>Error Message: {sdkConfiguration.errorMessage}</div>
        </div>
      ) : (
        <div>
          <h1 style={{ marginTop: 0 }}>OneSignal Website SDK Test App</h1>
          <h2>How to use</h2>
          <p>
            You can use this app to load the OneSignal Website SDK and initialize it for any app you
            like! Just set https://www.nicktest.org as the app's domain and then paste the app ID
            into the box below instead of using the provided snippet.
          </p>
          <p>
            Once you click either load button, the page will refresh to a url for that app
            configuration. Feel free to share this url with colleagues so they can easily add
            themselves as subscribers to your OneSignal app.
          </p>
          <p>
            Once you see an app configuration that's successfully loaded, your app's prompt will
            come down as usual. You can click the reset button on that page to clear all locally
            stored data
          </p>
          <p>If you find bugs, please let Nick A know.</p>
          <h2>Notes</h2>
          <p>
            <em>
              <strong>Note</strong>: unlike most integrations of our web SDK, this site can be
              loaded with any app configuration without issue (bugs notwithstanding). If you follow
              a link to this site for a colleague's app but were recently using it for your own, it
              will try to clear out the local site data for your app and then initialize theirs
              seamlessly.
            </em>
          </p>
          <p>
            <em>
              <strong>Warning</strong>: don't open multiple tabs of this site. That can cause
              multiple app configurations to be initialized at once, which causes misbehavior.
            </em>
          </p>
          <div>
            <div style={{ paddingTop: 20 }}>
              App ID:&nbsp;
              <input
                type="text"
                style={{ width: 500 }}
                value={newAppIdDraft}
                onChange={({ target: { value } }) => setNewAppIdDraft(value)}
              />
            </div>
            <div style={{ display: "flex" }}>
              <div style={{ paddingTop: 20, paddingRight: 20 }}>
                <button
                  disabled={!newAppIdDraft}
                  onClick={() =>
                    window.location.assign(`./?app_id=${newAppIdDraft}&sdk_environment=production`)
                  }
                >
                  Load production app
                </button>
              </div>
              <div style={{ paddingTop: 20 }}>
                <button
                  disabled={!newAppIdDraft}
                  onClick={() =>
                    window.location.assign(`./?app_id=${newAppIdDraft}&sdk_environment=staging`)
                  }
                >
                  Load staging app
                </button>
              </div>
            </div>
            <div style={{ paddingTop: 20, fontStyle: "italic" }}>
              Note: staging doesn't seem to be initializing realiably right now. Updated 2021-02-23.
            </div>
          </div>
        </div>
      )}
      {sdkConfiguration && (
        <div style={{ paddingTop: 40 }}>
          <button onClick={() => window.location.assign("./?clear_storage=true")}>Reset</button>
        </div>
      )}
    </div>
  )
}

export default App
